.parallels-btn{
  border-radius: 15px;
  transition: background-color .5s;
}

.parallels-btn:hover{
  background-color: #DE3435;
  border: 0;
}

.save-video__btn{
  border-radius: 15px;
  transition: background-color .5s;
  margin-top: 2rem;
}

.save-video__btn:hover{
  background-color: #DE3435;
  border: 0;
}