.header {
  margin: 0;
  padding: 2rem 0;
  position: relative;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  background: #F7F8FB;
  @media screen and (max-width: 1465px) {
    padding: 2rem
  }
  &-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1450px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 910px){
      flex-direction: column;
      align-items: center;
    }
  }
  &__center{
    display: flex;
    @media screen and (max-width:910px){
     flex-direction: column;
      align-items: center;
      margin: 1rem 0;
    }
  }
  &__center-input{
    width: 20rem;
    border: 1px solid white;
    border-radius: 25px;
    padding: .5rem;
    font-family: Mulish-Regular;
    margin-right: 2rem;
    margin-left: 7rem;
    @media screen and (max-width:970px){
      width: 15rem;
      margin: 1rem;
    }
  }
  &__btn{
    margin-right: 2rem;
    @media screen and (max-width: 715px) {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}

.header__social-links{
  display: flex;
  align-items: center;
  @media screen and (max-width: 715px){
    flex-direction: column;
    justify-content: center;
  }
  &-blog{
    @media screen and (max-width: 715px) {
      width: 100%;
    }
  }
}

.social-icon{
  width: 40px;
  height: 25px;
  color: #C1C2D1;
  cursor: pointer;
  transition: color 1s;
  @media screen and (max-width: 900px){
  }
}
.social-icon:hover{
  color: blue;
}