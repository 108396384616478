.login-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
}

.login-input{
  margin: .5rem;
  padding: 1rem;
  background-color: white;
  border-radius: 15px;
  border-color: #1934ea;
  font-size: 18px;
  width: 400px;
  @media screen and (max-width: 440px){
    width: 300px;
  }
}

.input-password{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.password-icon{
  cursor: pointer;
  position: absolute;
  right: 6%;
  &__img{
    width: 20px;
    height: 20px;
  }
}


.btns{
  display: flex;
  @media screen and (max-width: 558px) {
    flex-direction: column;
  }
}

.login-btn{
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: .3rem;
  max-width: 400px;
  text-align: center;
}

.login-support{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: .5rem;
  &__a{
    margin: .5rem;
  }
}

.login-title{
  margin-bottom: 1rem;
  @media screen and (max-width: 1000px){
    text-align: center;
  }
}

