.login-form__modal{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-btn__modal{
  margin: 1rem 0 0;
  max-width: 400px;
  text-align: center;
}