.materials-cards{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 3rem auto;
  @media screen and (max-width: 1380px){
    justify-content: center
  }
  @media screen and (max-width: 715px){
    display: none;
  }
  &__img{
    width: 28rem;
    @media (max-width: 1380px) {
      margin: 1rem;
    }
    @media (max-width: 455px){
      padding: 1rem 0;
      width: 20rem;
    }
  }
}

.materials-cards__mobile{
  @media screen and (min-width: 716px){
    display: none;
  }
  &-img{
    width: 100%;
     margin-bottom: 1rem;
  }
}