//настройки кнопок
.cards-router{
  margin: 3rem 0;
  @media screen and (max-width: 1400px) {
  }
}

.cards-router__h2{
  font-size: 20px;
  font-weight: 600;
  color: #5E5E62;
}

.router-btns{
  @media screen and (max-width: 965px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.btn-arrow{
  margin-top: 1rem;
  margin-right: .5rem;
  background: #3D73FD;
  border: 1px solid #3D73FD;
}

//настройки карточек
.cards__img{
  width: 100%;
  height: 350px;
  border-radius: 20px;
}

.allCards{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  grid-gap: 10px;  @media screen and (max-width: 967px){
  justify-items: center;
}
}

.oneCard{
  margin-top: 20px;
  max-width: 350px;
  &-p{
    font-weight: 400;
    font-size: 16px;
    color: #1E2028;
    margin-top: .5rem;
  }
}

