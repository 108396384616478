.register-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
}

.register-input{
  margin: .5rem;
  padding: 1rem;
  background-color: white;
  border-radius: 15px;
  border-color: #1934ea;
  font-size: 18px;
  width: 400px;
  @media screen and (max-width: 440px){
    width: 300px;
  }
}

.register-input__password{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btns{
  display: flex;
  margin-bottom: 1rem;
  @media screen and (max-width: 558px) {
    flex-direction: column;
  }
}

.register-btn{
  margin-top: 1rem;
  margin-right: .3rem;
  max-width: 400px;
  text-align: center;
}

.register-approval__link{
  color: #1934ea;
  text-decoration: underline;
}

.password-icon{
  cursor: pointer;
  position: absolute;
  right: 6%;
  &__img{
    width: 20px;
    height: 20px;
  }
}
