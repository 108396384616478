.single-project{
  display: flex;
  margin-bottom: 2rem;
  @media screen and (max-width: 715px){
    flex-wrap: wrap;
    justify-content: center;
  }
}

.project-img{
  width: 300px;
  height: 200px;
  margin-right: 2rem;
  border-radius: 20px;
  @media screen and (max-width: 715px){
    max-width: 335px;
    margin: 0;
  }
}

.project-description{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 715px){
    margin: 0 3rem 2rem;
  }
  &__title{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: .5rem;
    @media screen and (max-width: 715px) {
      margin-top: 1rem;
    }
  }
  &__p{
    font-size: 16px;
    font-weight: 400;
    @media screen and (max-width: 715px) {
      margin-top: 1rem;
      color: #232428;
    }
  }
  &__link{
    color: #232428;
    text-decoration: underline;
    font-size: .8rem;
    font-weight: 400;
    @media screen and (max-width: 715px) {
      margin-top: 1rem;
    }
  }
}