@import "~react-image-gallery/styles/scss/image-gallery.scss";

//Настройки галереи
.gallery-section{
  max-width: 1450px;
  margin: .5rem auto;
  padding: 2rem 0;
}

.gallery-section__block{
  margin: .5rem;
}

.image-gallery-svg{
  width: 30px !important;
}

//.gallery{
//  display: flex;
//  flex-wrap: wrap;
//  justify-content: center;
//  &-title{
//    @media screen and (max-width: 700px) {
//      text-align: center;
//      padding: 0;
//      margin-left: 0;
//    }
//  }
//}