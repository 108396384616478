.manuals-cards{
  //display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  //display: flex;
  //justify-content: flex-start;
  //@media screen and (max-width: 1200px){
  //  flex-wrap: wrap;
  //}
  //@media screen and (max-width: 500px){
  //  //max-width: 350px;
  //}
}

.sborniks, .checkLists{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  @media screen and (max-width: 932px){
    justify-items: center;
  }
}

.check-h2{
  margin-top: 2rem;
}

.one-sbornik, .one-checkList{
  width: 335px;
  margin-bottom: 2rem;
  @media screen and (max-width: 1340px){
    width: 300px;
  }
  @media screen and (max-width: 932px){
    padding-bottom: 1rem;
    text-align: center;
  }
  @media screen and (max-width: 337px) {
    width: 300px;
  }
}

.manuals-image{
  max-width: 335px;
  @media screen and (max-width: 1340px){
    width: 300px;
  }
  @media screen and (max-width: 337px){
    max-width: 100%;
  }
}

.manuals-description{
  font-size: 16px;
  font-weight: 400;
  color: black;
}