//Настройка верхнего блока с логотипами
.brandbook-logo__section{
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.brandbook-logo__section-left{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  @media screen and (max-width: 1180px) {
    width: 60%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.brandbook-logo__section-right{
  width: 30%;
  background: #F0F1F5;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 1180px) {
    width: 40%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 1rem 0;
  }
}

.brandbook-logo__section-left-upper, .brandbook-logo__section-left-down{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 20px;
  font-family: Mulish-Regular;
  font-size: 16px;
}

.brandbook-logo__section-left-upper  h2, .brandbook-logo__section-left-down h2{
  font-family: Montserrat-Alternates;
  font-weight: 600;
  font-size: 28px;
}

.brandbook-logo__section-left-upper{
  background: #F0F1F5;
  color: black;
  height: 190px;
  @media screen and (max-width: 900px) {
    height: auto;
  }
}

.brandbook-logo__section-left-down{
  background: #1E2028;
  color: white;
  height: 325px;
  @media screen and (max-width: 900px) {
  height: auto;
}

}

.brandbook-logo__section-right-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 330px;
  height: 140px;
  background: white;
  border-radius: 20px;
  gap: 10px;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  cursor: pointer;
  @media screen and (max-width: 400px) {
    width: 300px;
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(0.95);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.brandbook-logo{
  width: 50%;
  height: 50%;
  transition: transform 0.3s ease;
  @media screen and (max-width: 900px) {
    animation: zoomInOut 2s ease-in-out infinite;
  }
}

.brandbook-logo__section-right-block:hover .brandbook-logo{
  transform: scale(1.1);
}

.overlay {
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 16px;
  color: #232428;
  @media screen and (max-width: 900px) {
    opacity: 1;
  }
}

.brandbook-logo__section-right-block:hover .overlay{
  opacity: 1;
}

.brandbook-logo__section-right-block-blue{
  background: radial-gradient(209.65% 242.7% at 5.02% -11.87%, #4A5CF8 0%, #8CACFD 100%);
}

.brandbook-logo__section-right-block-blue .overlay{
  color: white;
}


//Настройка блока со значениями символов
.brandbook-symbols{
  margin: 3rem auto;
  border-radius: 20px;
  padding: 2rem;
  color: white;
  background: radial-gradient(209.65% 242.7% at 5.02% -11.87%, #4A5CF8 0%, #8CACFD 100%);

  //background: radial-gradient(#8CACFD, #4A5CF8 );
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  @media screen and (max-width: 700px) {
    background: #1E2028;
  }
}

.brandbook-symbols h2{
  font-family: Montserrat-Alternates;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
}

.brandbook-symbols__icon{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 1rem;
  & svg{
    width: 40px !important;
    height: 40px !important;
    flex-shrink: 0;
  }
}

//Настройка блока с неправильным использованием логотипов
.invalid{
  display: flex;
  flex-direction: column;
  background: #F0F1F5;
  border-radius: 20px;
  padding: 2rem;
  @media screen and (max-width: 420px){
    padding: 1rem;
  }
  &-cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    @media screen and (max-width: 1195px) {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
    &__card{
      width: 400px;
      @media screen and (max-width: 1340px){
        width: 350px;
      }
      @media screen and (max-width: 420px){
        width: 280px;
      }
    }
    &__card img{
      width: 100%;
    }
  }
}

.invalid h2{
  font-family: Montserrat-Alternates;
  font-weight: 700;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

//настройка блока с шрифтами и цветами
.brandbook-fonts{
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
    width: auto;
}
}

.brandbook-fonts__fonts, .brandbook-fonts__colors{
  padding: 2rem;
  border-radius: 20px;
  height: 767px;
  @media screen and (max-width: 950px) {
    height: 100%;
    width: auto;
  }
}

.brandbook-fonts__fonts{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: radial-gradient(209.65% 242.7% at 5.02% -11.87%, #4A5CF8 0%, #8CACFD 100%);
  color: white;
  & h2{
    font-family: Montserrat-Alternates;
    margin-bottom: 2rem;
    font-size: 28px;
  }
}

.brandbook-fonts__fonts-down{

  &-examples{
    //display: grid;
    //grid-template-columns: repeat(1, 1fr);
    //gap: 1px;
    @media screen and (max-width: 500px){
      max-width: 350px;
    }
    &-Montserrat{
      & h3{
        font-size: 96px;
        font-weight: 600;
      }
      & p{
        font-family: Montserrat-Alternates;
        word-break: break-all;
      }
    }
    &-Mulish{
      & h3{
        font-family: Mulish-Regular;
        font-size: 96px;
        font-weight: 600;
      }
      & p{
        word-break: break-all;
      }
    }
  }
  &-btn{
    padding: 16px 41px;
    width: 100%;
    background: white;
    cursor: pointer;
    border: 0;
    border-radius: 100px;
    font-size: 16px;
    color: #1E2028;
    margin-top: 2rem;
  }
}

.brandbook-fonts__colors{
  background: #F0F1F5;
  color: #232428;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & h2{
    font-family: Montserrat-Alternates;
    font-size: 28px;
    margin-bottom: 2rem;
  }
  &-examples{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    color: white;
    @media screen and (max-width: 940px) {
      margin-top: 10px;
    }
    @media screen and (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }
    &-blue{
      background: #1934ea;
      color: white;
      border-radius: 20px;
      padding: 2rem 2rem 4rem;
      margin-bottom: 10px;
      @media screen and (max-width: 940px) {
        margin: 2rem 0 0;
      }
    }
    &-lightblue{
      border-radius: 20px;
      background: #6B7FFF;
      padding: 2rem;
    }
    &-softblue{
      background: #4A5CF8;
      padding: 2rem;
      border-radius: 20px;
    }
    &-darkblue{
      background: #041873;
      padding: 2rem;
      border-radius: 20px;
    }
    &-black{
      background: #2D2E33;
      padding: 2rem;
      border-radius: 20px;
    }
  }
}

//Настройка секции с проектами
.brandbook-projects{
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
  &__block{
    width: 40%;
    background: #F0F1F5;
    padding: 2rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
    & h2{
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 600;
    }
    &-projects{
      display: flex;
      flex-direction: column;
      gap: 10px;
      & a{
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        & svg{
          width: 64px !important;
          height: 64p !important;
          flex-shrink: 0;
        }
      }
    }
  }
}

.brandbook-all__download{
  width: 60%;
  border-radius: 20px;
  background-image: url("./assets/all-background.webp");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 700px) {
    width: 100%;
    gap: 150px;
    background-image: url("./assets/all-background-mobile.webp");
  }
  &-title{
    font-size: 36px;
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (max-width: 700px) {
      font-size: 22px;
    }
  }
  &-btn{
    color: #1E2028;
    background: #ffffff;
    padding: 22px 28px 24px;
    border-radius: 100px;
    width: 350px;
    height: 52px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
}