.employee-header__main{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 1rem;
  @media screen and (max-width: 890px) {
    flex-direction: column;
  }
}

.employee-boss{
  width: 50%;
  @media screen and (max-width: 890px) {
    width: 100%;
  }
}

.employee-big__card{
  position: relative;
  width: 100%;
  background: #F7F8FB;
  border-radius: 20px;
  max-height: 800px;
  height: 100%;
  @media screen and (max-width: 890px) {
    max-height: inherit;
    display: flex;
    flex-direction: row;
  }
  @media screen and (max-width: 530px) {
    flex-direction: column;
  }
}

.employee-photo__big{
  width: 100%;
  height: 550px;
  object-fit: cover;
  object-position: center top;
  border-radius: 20px 20px 0 0;
  @media screen and (max-width: 890px){
    height: 400px;
    border-radius: 20px 0 0 20px;
  }
  @media screen and (max-width: 530px) {
    border-radius: 20px 20px 0 0;
  }
}

.employee-card__big-text{
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  @media screen and (max-width: 890px) {
    height: 17rem;
    white-space: pre-line;
  }
}

.employee-fio__big{
  font-weight: 700;
  font-size: 2rem;
  color: #232428;
  margin-bottom: .5rem;
  @media screen and (max-width: 890px){
    font-size: 25px;
  }
}

.employee-card__aside{
  position: relative;
  width: 100%;
  background: #F7F8FB;
  border-radius: 20px;
  display: flex;
  height: 50%;
  @media screen and (max-width: 890px) {
    flex-direction: column;
    height: auto;
    width: 50%;
  }
  @media screen and (max-width: 530px) {
    width: 100%;
  }
}

.employee-card__list{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: 890px) {
    width: 100%;
    flex-direction: row;
  }
  @media screen and (max-width: 530px) {
    flex-direction: column;
  }
}

.employee-about__link{
  color: #8d8e92;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  @media screen and (max-width: 890px) {
    bottom: 1rem;
  }
}
