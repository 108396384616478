*{
  box-sizing: border-box;
  margin: 0;
  @font-face {
    font-family: 'Mulish-Regular';
    src: local('Mulish-Regular'),
    url("./fonts/Mulish/static/Mulish-Regular.ttf") format('truetype');
  }
  @font-face {
    font-family: 'Montserrat-Alternates';
    src: local('Montserrat-Alternates'),
    url('./fonts/Montserrat Alternates/MontserratAlternates-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Montserrat-Alternates-Bold';
    src: local('Montserrat-Alternates'),
    url('./fonts/Montserrat Alternates/MontserratAlternates-Bold.ttf') format('truetype');
  }
}

.main_title{
  opacity: 0;
  z-index: -5;
  position: absolute;
}

.title-h1 {
  font-family: Montserrat-Alternates;
  font-size: 3rem;
  margin: 2rem 0;
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 370px) {
    font-size: 1.5rem;
  }
}

.title-h2{
  font-family: Montserrat-Alternates;
  color: #5E5E62;
  font-size: 2.4rem;
  margin: 2rem 0;
  @media screen and (max-width: 700px) {
    font-size: 1.5rem;
  }
  &__manuals{
    font-family: Montserrat-Alternates;
    color: #5E5E62;
    font-size: 1.5rem;
    margin: 2rem 0;
    font-weight: 600;
  }
}

.title-h3{
  font-family: 'Montserrat-Alternates';
  color: #5E5E62;
  font-size: 2rem;
  margin: 2rem 0;
  //@media screen and (max-width: 1400px) {
  //  margin-left: 2rem;
  //}
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-family: Montserrat-Alternates;
}

p,
a,
input,
button,
ul,
li{
  font-family: 'Mulish-Regular';
}

ul{
  list-style-type: none;
}

.button{
  background-color: #1934ea;
  border-radius: 25px;
  border: 1px solid #1934ea;
  color: #fff;
  cursor: pointer;
  font-family: 'Mulish-Regular';
  padding: .5rem 3rem;
  font-size: 1.1rem;
}

a{
  text-decoration: none;
  color: unset;
  //color: #1934ea;
}

//Настройки боковой панели для админки
.admin-page__sidebar{
  position: fixed;
  top: 0;
  z-index: 0;
}

.admin-page__content{
  margin-left: 20rem;
}

//Общие настройки страниц
.page{
  max-width: 1450px;
  margin: 3rem auto;
  @media screen and (max-width: 1450px){
    margin: 0 1rem 1rem;
  }
  @media screen and (max-width: 715px) {
    margin: -2rem 0 2rem;
    background: white;
    position: relative;
    z-index: 999;
    border-radius: 30px;
    padding: 1rem;
    width: 100%;
  }
}

//Стили для заглавных картинок на страницах
.title-image{
  position: relative;
  max-width: 1450px;
  width: 100%;
  margin: 2rem auto;
  position: relative;
  z-index: 0;
  @media screen and (max-width: 1450px){
    //max-width: 1200px;
    padding: 0 1rem;
  }
  @media screen and (max-width: 715px) {
    display: none;
  }
  &__img{
    width: 100%;
  }
  //width: -webkit-fill-available;
  //@media screen and (max-width: 700px){
  //  display: none;
  //}
}

.title-image__img-news{
  max-width: 1450px;
  width: 100%;
  margin: 2rem auto;
  position: relative;
  z-index: 0;
  border-radius: 30px;
  @media screen and (max-width: 1450px){
    //max-width: 1200px;
    margin: 0 auto;
  }
  @media screen and (max-width: 715px) {
    display: none;
  }
}

.title-image__mobile{
  @media screen and (min-width: 716px) {
    display: none;
  }
}

.title-image__img-mobile{
  width: 100%;
}

.title-section{
  background-color: #1934ea;
  color: white;
  padding: 5rem;
  margin-bottom: 2rem;
  &__h3{
    font-family: Montserrat-Alternates;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

.title-mobile{
  background-color: #1934ea;
  color: white;
  padding: 3rem;
  height: 200px;
  @media screen and (min-width: 716px){
    display: none;
  }
  &__h3{
    font-family: Montserrat-Alternates;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

//Настройка модальных окон
.modal{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: Modal_animation .5s forwards;
  &Title{
    word-break: inherit;
    text-align: center;
  }
}

@keyframes Modal_animation {
  0% {opacity: 0}
  100% {opacity: 1}
}


.modal__content{
  position: absolute;
  background: white;
  border-radius: 20px;
  padding: 25px;
  min-width: 250px;
  max-width: 800px;
  max-height: 95vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.modal__header,
.modal__footer{
  padding: 1rem;
}

.modal__body{
  padding: 1rem;
}

.modal__footer {
  display: flex;
  justify-content: space-between;
}

.modal.active{
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close__cross{
  position: absolute;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
}

//Форма регистрации
.regForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 700px;
  @media screen and (max-width: 790px) {
    width: 450px;
  }
  @media screen and (max-width: 540px) {
    width: 320px;
  }
}

.regInput{
  padding: .5rem;
  font-size: 1rem;
  border-radius: 20px;
}

.regInput__checkbox{
  font-family: Mulish-Regular;
  display: flex;
  flex-direction: row;
  &-galka{
    margin-right: .5rem;
  }
}

.regLabel{
  display: flex;
  font-family: Mulish-Regular;
  flex-direction: column;
  margin-top: 1rem;
  //align-items: flex-start;
}

#form-of-participation,
#name-of-journal,
#education-program,
#category-manuals,
#category-cards,
#department-id__employee,
#position-id__employee{
  font-family: Mulish-Regular;
  padding: .5rem;
  font-size: 1rem;
  border-radius: 20px;
  width: -webkit-fill-available ;
  margin-top: .2rem;
}

.form-btn{
  width: 50%;
  margin: 1rem auto 0;
}

.regInput__file{
  font-family: Mulish-Regular;
  display: flex;
  flex-direction: column;
}

.disabledButton{
  opacity: 30%;
  cursor: auto;
}

.notFound-title{
  margin-top: 2rem;
}

//Сообщение об ошибке в формах
.wrong-message{
  text-align: center;
  color: #DE3435;
  font-size: 14px;
}

.news-source_link, .education-link{
  color: #1934ea;
  cursor: pointer;
}