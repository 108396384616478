.page-desc{
  margin-top: -1rem;
  font-size: 1.2rem;
  padding: 2rem 15rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  @media screen and (max-width: 1220px) {
    padding: 2rem 10rem;
  }
  @media screen and (max-width: 900px) {
    padding: 2rem 8rem;
  }
  @media screen and (max-width: 600px) {
    padding: 2rem .5rem;
  }
}

.education-title{
  text-align: start;
}

.education-section{
  max-width: 1450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //@media screen and (max-width: 1200px) {
  //  margin: 3rem 0;
  //}
}

.education-h{
  margin-bottom: 1rem;
}

.education-img{
  max-width: 600px;
  width: 50%;
  border-radius: 20px;
  @media screen and (max-width: 1200px) {
    max-width: 500px;
    width: 100%;
  }
  @media screen and (max-width: 510px) {
    max-width: 350px;
  }
  @media screen and (max-width: 350px) {
    max-width: 320px;
  }
}

.education-block{
  border-radius: 20px;
  margin: .5rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1400px) {
    padding: 1rem ;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding: 2rem ;
  }
}
.education-text{
  padding: 5rem;
  width: 50%;
  @media screen and (max-width: 1200px) {
    padding: 1rem;
    text-align: center;
    width: 100%;
  }
  &__p{
    padding: 1rem 0;
    &-italics{
      font-style: italic;
      font-weight: 600;
      padding-top: 1rem;
    }
  }
}

.education-ul{
  padding: 0;
}


.education-oneImg{
  max-width: 400px;
  height: 250px;
  margin: 1rem;
  @media screen and (max-width: 400px) {
    max-width: 320px;
  }
}

//Настройки карточек
.education-section__DPO{
  max-width: 1450px;
  margin: .5rem auto;
  padding: 2rem 0;
}

.education-block__DPO{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 931px){
    justify-content: space-evenly;
  }

  &-img{
    max-width: 300px;
    border-radius: 40px;
    cursor: pointer;
  }
  &-p{
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
  }
  &-end{
    font-size: 1.5rem;
    margin-top: 5rem;
    text-align: center;
    background: #F7F8FB;
    border-radius: 30px;
    padding: 3rem;
  }
}

.education-card__DPO{
  max-width: 300px;
  @media screen and (max-width: 900px){
    margin-bottom: 2rem;
  }
}

//блок контактов
.education-contacts__section{
  max-width: 1450px;
  margin: 3rem auto 0;
}

.education-contacts__block{
  padding: 2rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #F7F8FB;
  &-title{
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  &-link{
    text-decoration: underline;
    font-weight: 700;
  }
  &-text{
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}