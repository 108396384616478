.catalog-journals{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: 1400px){
    margin-left: 1rem;
  }
  @media screen and (max-width: 800px) {
    justify-content: center;
  }
}

.journal-image{
  width: 230px;
  height: 290px;
  margin: 1rem;
}

.catalog-btns{
  @media screen and (max-width: 1400px){
    margin-left: 1rem;
  }
}

.catalog-button{
  font-size: 14px;
  width: 65px;
  height: 35px;
  padding: .1rem;
  margin: .1rem;
  color: #718096;
  background-color: white;
  border-color: white;
}

.catalog-button:hover{
  color: #1934ea;
  transition: color 1s
}

.catalog-button__active{
  font-size: 14px;
  width: 65px;
  height: 35px;
  padding: .1rem;
  margin: .1rem;
  color: white;
  background-color: #1934ea;
  border-color: #1934ea;

}
