.allCourses{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 985px){
    justify-content: center;
  }
}

.course{
  background: #F7F8FB;
  border-radius: 20px;
  margin-bottom: 2rem;
  display: flex;
  @media screen and (max-width: 1020px) {
    flex-direction: column;
  }
  &-img{
    margin-right: 3rem;
    @media screen and (max-width: 1020px) {
      margin: 1rem auto;
    }
    &__img{
      width: 400px;
      height: -webkit-fill-available;
      border-radius: 20px 0 0 20px;
      @media screen and (max-width: 1020px){
        max-width: 400px;
        width: 100%;
        height: auto;
        border-radius: 20px;
        margin: 1rem auto;
      }
    }
  }
}

.course-description{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 2rem 2rem 0;
  @media screen and (max-width: 1020px) {
    padding: 1rem 2rem;
  }
  &__price{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 720px) {
      margin-top: 1rem;
      flex-direction: column;
      align-items: stretch;
    }
  }
}

.course-price{
  font-size: 28px;
  font-weight: 600;
  font-family: Montserrat-Alternates;
  &__number{
    font-size: 20px;
    font-weight: 500;
    color: #898989;
  }
  &__description{
    color: #898989;
    font-size: 16px;
    font-weight: 400;
    font-family: Mulish-Regular;
  }
}

.course-title{
  font-size: 22px;
  font-weight: 700;
  color: #232428;
  margin-bottom: .5rem;
  max-width: 900px;
}

.course-desc{
  font-weight: 400;
  font-size: 16px;
  color: #232428;
  margin-bottom: 2rem;
}

.course-btn{
  background: #6E7DF9;
  border: 1px solid #6E7DF9;
  color: white;
  padding: 1rem 3rem;
}

.course-description__price-text{
  @media screen and (max-width: 1260px) {
    max-width: 400px;
  }
  @media screen and (max-width: 720px) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

#visit_format{
  display: none;
}

.hide-element{
  display: none;
}