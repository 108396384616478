.one-table{
  margin-bottom: 5rem;
}

.applications-table{
  border: 1px solid black;
  text-align: center;
  border-collapse: collapse;
  max-width: 94rem;
  margin-right: 1rem;
  display: block;
  overflow-x: auto;
  //white-space: nowrap;
  font-family: Mulish-Regular;
  margin-bottom: 2rem;
  &__title{
    margin-bottom: 1rem;
  }
  &__thead{
    &-th{
      border: 1px solid black;
      border-collapse: collapse;
      padding: 5px;
    }
  }
  &__tbody{
    &-td{
      border: 1px solid black;
      border-collapse: collapse;
      padding: 5px;
    }
  }
}
