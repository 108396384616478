.obzor-description{
  display: flex;
  padding: 0 1.5rem;
  margin-bottom: 5rem;
  margin-top: 2rem;
  @media screen and (max-width: 1255px) {
    align-items: center;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  &__text{
    padding-left: 3rem;
    font-size: 1.2rem;
    @media screen and (max-width: 1000px) {
      padding: 0;
    }
    &-h1{
      margin-top: 0;
      @media screen and (max-width: 1000px) {
        text-align: center;
        margin-left: 0;
        margin-top: 1rem;
       }
    }
    &-a{
      color: #1934ea;
    }
    &-btn{
      margin-top: 2rem;
     }
  }
  &__img{
    max-width: 500px;
    max-height: 444px;
    border-radius: 20px;
    @media screen and (max-width: 505px) {
      max-width: 330px;
    }
  }
}

.obzor-deadlines{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 700px){
    flex-direction: column;
    align-items: center;
  }
  &__imgs{
    max-width: 260px;
    padding: 1rem;
    @media screen and (max-width: 525px){
      display: flex;
      flex-direction: column;
    }
  }
  &__text{
    &-p{
      padding: 1rem;
      font-size: 1.2rem;
    }
    &-h3{
      color: black;
      padding-left: 1rem;
      @media screen and (max-width: 700px){
        text-align: center;
        padding: 0;
        margin-left: 0;
      }
    }
  }
}

.obzor-themes{
  padding: 0 1rem;
  margin-top: 5rem;
  &__h3{
    margin-bottom: 1rem;
    color: black;
  }
  &__p{
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
  }
}

.obzor-application{
  margin-top: 3rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  &__a{
    color: #1934ea;
    font-size: 1.2rem;
    font-weight: 600;
  }
  &__p{
    font-size: 1.2rem;
    margin: 1rem 0;
  }
  &__btn{
    margin: 2rem 0;
    max-width: 400px;
  }
}

