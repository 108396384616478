//Настройки страниц с видеозаписями "Видеоматериалы" и "Работы конкурса Параллели"
//Настройки блоков с видео и самих видео
.video-containers{
  max-width: 1450px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  grid-gap: 10px;
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: flex-start;
  //gap: 20px;
  @media screen and (max-width: 951px){
      justify-items: center;
    }
  &__title{
    margin-top: 5rem;
  }
}

.video-section{
  position: relative;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video{
  width: 100%;
  height: 250px;
  border-radius: 20px;
  @media screen and (max-width: 951px){
    max-width: 100%;
  }
}

.video-text{
  margin-top: 10px;
  text-align: center;
  font-size: 1rem;
}

.video-btn{
  background: white;
  color: black;
  border: 1px solid #E5E7EB;
  font-weight: 600;
  font-size: 16px;
  margin: .5rem;
}

.video-btn__active{
  background: #F0F1F5;
  border: 1px solid #F0F1F5;
  color: black;
  font-weight: 600;
  font-size: 16px;
  margin: .5rem;
}

.page-btns{
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 565px) {
    flex-direction: column;
    align-items: center;
  }
}

