.team-title{
  display: flex;
  justify-content: center;
  margin: 0 auto 2rem;
  max-width: 1450px;
}

.team-title__img-main{
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 50px;
  object-position: top;
  @media screen and (max-width: 870px) {
    object-fit: fill;
    border-radius: 50px;
    height: auto;
  }
}

.team-title__img{
  width: 100%;
  height: 550px;
  object-fit: cover;
  border-radius: 50px;
  object-position: top;
  @media screen and (max-width: 870px) {
    object-fit: fill;
    border-radius: 50px;
    height: auto;
  }
}

.directors-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  @media screen and (max-width: 740px){
    flex-direction: column;
    align-items: center;
}
}

.directors-card{
    display: flex;
    max-height: 300px;
    width: 49%;
    background: #F7F8FB;
    border-radius: 20px;
  @media screen and (max-width: 740px) {
    max-height: none;
    width: 100%;
    margin-bottom: 2rem;
  }
  &__img {
    width: 50%;
    &-img {
      //max-width: 250px;
      height: 300px;
      width: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 20px 0 0 20px;
      margin-right: 1rem;
    }
  }
  &__text{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    margin-left: 1rem;
    &-h{
      font-family: Mulish-Regular;
      font-weight: 700;
      font-size: 32px;
      color: #232428;
      @media screen and (max-width: 1085px) {
        font-size: 25px;
      }
      @media screen and (max-width: 360px) {
        font-size: 20px;
      }
    }
    &-p{
      font-weight: 400;
      font-size: 16px;
      color: #232428;
    }
  }
}

.team-links{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 740px) {
    flex-direction: column;
    max-height: none;
    width: 100%;
    margin-bottom: 2rem;
  }
  &__h{
    margin-top: 3rem;
    font-family: Montserrat-Alternates;
    font-size: 28px;
    font-weight: 600;
    color: #5E5E62;
  }
  &__card{
    margin-bottom: 2rem;
    background: #F7F8FB;
    border-radius: 20px;
    width: 49%;
    @media screen and (max-width: 740px) {
      width: 100%;
    }
      &-img{
      width: 100%;
      height: 350px;
      object-fit: cover;
      object-position: top;
      border-radius: 20px 20px 0 0;
      @media screen and (max-width: 900px) {
        object-fit: fill;
      }
        @media screen and (max-width: 740px) {
          object-fit: cover;
          height: 250px;
        }
    }
  }
}

.team-links__card-text-block{
  border-radius: 0 0 20px 20px;
  padding: 2rem;
  font-size: 20px;
  font-weight: 600;
  color: #232428;
}

//настройки карточек работников
//.employee-page{
//  display: flex;
//  flex-wrap: wrap;
//  justify-content: space-between;
//}

.employee{
  background: #F7F8FB;
  border-radius: 20px;
  display: flex;
  height: 50%;
  width: 49.5%;
  margin-bottom: 1rem;
  position: relative;
  @media screen and (max-width: 890px) {
    flex-direction: column;
    width: 49%;
    height: auto;
  }
  @media screen and (max-width: 530px) {
    width: 100%;
  }
  &-photo{
    max-width: 40%;
    height: 390px;
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 20px 0 0 20px;
    @media screen and (max-width: 890px){
      max-width: inherit;
      border-radius: 20px 20px 0 0;
  }
  }
  &-fio{
    font-weight: 700;
    font-size: 2rem;
    color: #232428;
    margin-bottom: 1rem;
    @media screen and (max-width: 1026px){
      font-size: 25px;
    }
  }
  &-work__position{
    padding: .5rem 0;
  }
  &-p{
    white-space: pre-wrap;
  }
}

.employee-card__text{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 2rem;
}

.employee-cards__section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  //gap: 15px;
}
