.footer{
  font-family: Mulish-Regular;
  background: #1E2028;
  width: 100%;

  //margin-top: 10rem;
  padding: 5rem 6.25rem 2.6rem;
  &-menu{
    padding: 0 5em;
  }
  &__social-icons{
    padding-top: 1rem;

    display: flex;
    align-items: center;
  }
  &__text-info{
    display: flex;
    flex-direction: column;
  }
  &__text-info a{
    padding-bottom: 1rem;
    @media screen and (max-width: 900px){
      text-align: center;
    }
  }
  @media screen and (max-width: 900px){
   padding: 5rem 1rem 1rem;
  }
}

.footer-main{
  max-width: 1450px;
  margin: 0 auto;
}

.upper-block{
  display: flex;
  justify-content: space-between;
  color: #fff;
  @media screen and (max-width: 900px){
    flex-direction: column;
    align-items: center;
  }
}

.footer-logo__img{
  width: 12.5rem;
  height: 5rem;
}

.footer-logo,
.footer-menu,
.footer-contacts{
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  @media screen and (max-width: 900px){
    align-items: center;
  }
}

.footer-menu a, .footer-contacts a, .footer-p__contacts{
  font-size: 1rem;
  padding: .3rem 0;
  transition: color 1s;
  cursor: pointer;
}

.footer-menu a:hover, .footer-contacts a:hover{
  color: darkgrey;
}

.social-icons{
  width: 40px;
  height: 25px;
  color: #C1C2D1;
  cursor: pointer;
  transition: color 1s;
  @media screen and (max-width: 900px){
  }
}
.social-icons:hover{
  color: white;
}

.lower-block{
  padding-top: 2rem;
  color: #898989;
}

.footer-logo input{
  margin-top: 5rem;
  background: #1E2028;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 1rem;
  width: 20rem;
}

.footer__hide {
  display: none;
}


