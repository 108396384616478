.cards-settings__img{
  width: 150px;
}

.admin-title__h1-media{
  font-family: Montserrat-Alternates;
  font-size: 2rem;
  padding: 2rem 0 0;
  @media screen and (max-width: 370px) {
    font-size: 1.5rem;
  }
}

.admin-media__btns-section{
  margin: 1rem 0 3rem;
}

.admin-media__btn{
  margin-right: 1rem;
  border: 0;
  transition: background-color 1s;
}

.admin-media__btn:hover{
  background-color: #DE3435;
}

.admin-media__btn-active{
  margin-right: 1rem;
  border: 0;
  background-color: #DE3435;
}

.admin-video{
  border-radius: 20px;
}