.anonim-form{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  &__form{
    background: #f1f1f1;
    padding: 2rem;
    border-radius: 20px;
  }
  &__title{
    text-align: center;
    margin-bottom: 1rem;
  }
}