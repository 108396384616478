.approval-page{
  display: flex;
  margin: 5rem auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  &__title{

  }
}