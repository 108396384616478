.news-card{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  padding: 2rem;
  @media screen and (max-width: 820px){
    flex-direction: column;
  }
}

.title-image__news{
  max-width: 1400px;
  width: 100%;
}

.news-img{
  max-width: 400px;
  border-radius: 20px;
  @media screen and (max-width: 1070px){
    max-width: 350px;
    margin-left: 1rem;
  }
  @media screen and (max-width: 820px){
    margin-top: 2rem;
    margin-left: 0;
  }
  @media screen and (max-width: 450px){
    max-width: 230px;
  }
}

.news-desc{
  max-width: 700px;
  @media screen and (max-width: 450px) {
    word-break: break-word;
  }
}

.news-p{
  max-width: 750px;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.news-title{
  font-size: 2rem;
  @media screen and (max-width: 450px) {
    font-size: 1.4rem;
  }
}
