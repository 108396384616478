.forum{
  display: flex;
  margin-bottom: 5rem;
  align-items: center;
  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.forum-img{
  margin-right: 3rem;
  @media screen and (max-width: 715px) {
    margin-right: 0;
    margin-bottom: 3rem;
  }
  &__img{
    width: 300px;
    height: 250px;
    border-radius: 20px;
    @media screen and (max-width: 715px) {
      max-width: 400px;
      width: 100%;
      height: auto;
    }
  }
}

.forum-description{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  &__text{
    @media screen and (max-width: 715px) {
      margin-bottom: 1rem;
    }
    &-h2{
      font-weight: 700;
      font-size: 22px;
      @media screen and (max-width: 715px) {
        margin-bottom: 1rem;
        font-size: 16px;
      }
    }
    &-p{
      font-weight: 400;
      font-size: 18px;
      color: #232428;
      margin: 1rem 0;
      @media screen and (max-width: 715px) {
        font-size: 14px;
      }
    }
  }
  &__button{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1050px){
      margin-top: 1rem;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    @media screen and (max-width: 715px){
      align-items: stretch;
    }
    &-date{
      @media screen and (max-width: 1050px) {
        margin-bottom: 1rem;
      }
    }
  }
}

.forum-btn{
  background: #6E7DF9;
  border: 1px solid #6E7DF9;
  @media screen and (max-width: 715px){
    padding: 1rem;
  }
}


//.forums{
//  display: grid;
//  grid-gap: 1.5rem;
//  gap: 1.5rem;
//  grid-template-columns: repeat(4, minmax(250px, 400px));
//  @media screen and (max-width: 1150px){
//    grid-template-columns: repeat(3, minmax(200px, 450px));
//  }
//  @media screen and (max-width: 700px){
//    grid-template-columns: repeat(2, minmax(200px, 450px));
//  }
//  @media screen and (max-width: 470px){
//    grid-template-columns: 1fr;
//  }
//}
//
//.forum-section{
//  border-radius: 1rem;
//  height: 100%;
//  min-height: 450px;
//  overflow: hidden;
//}
//
//.forum{
//
//}
//
//.forum-title{
//  padding-top: 4.5rem;
//}
//
//.forum-title, .forum-date__p{
//  color: white;
//  text-shadow: 1px 1px black;
//}
//
//.forum-date{
//  display: flex;
//  align-items: center;
//  flex-direction: column;
//  gap: 1.5rem;
//}
//
//.forum-btn{
//  background-color: white;
//  border: 1px solid white;
//  color: black
//}

//Модальное окно форума
.forum-modal {
  text-align: start;
  &__p{
    margin-bottom: 1rem;
  }
  &__description{
    margin: 1rem 0;
  }
  &__h{
    font-size: 1.5rem;
    margin-bottom: .5rem;
  }
  &__p{
    font-size: 1.1rem;
    overflow-wrap: break-word;
  }
}
