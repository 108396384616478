.admin-page__nav {
  width: 260px;
  height: 100vh;
  font-size: 1.5rem;
  color: white;
  background: #1934ea;
  padding: 10rem 2rem 0;
  @media screen and (max-height: 660px) {
    padding: 5rem 2rem 0;
  }
}

.admin-page__nav-list{
  padding: 0;
  //height: 80vh;

}

.admin-page__nav-list__element{
    margin: 2rem 0;
}

.admin-page__nav-list__element:hover{
    color: #718096;
}

