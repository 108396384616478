.admin-title__h1{
  font-family: Montserrat-Alternates;
  font-size: 2rem;
  padding: 2rem 0;
  @media screen and (max-width: 370px) {
    font-size: 1.5rem;
  }
}

.admin-title__h2{
  font-family: Mulish-Regular;
  font-size: 1.4rem;
  margin: 1rem 0 2rem;
}

.admin-title__h3{
  font-family: Mulish-Regular;
  font-size: 1.4rem;
  margin-right: 2rem;
}

.admin-div{
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-around;
  flex-direction: row;
  margin-bottom: 2rem;
}

.admin-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

.admin-btn{
  border: 0;
  transition: background-color 1s;
}

.admin-btn:hover{
  background-color: #DE3435;
}

.admin-title__p{
  margin-right: 2rem;
  font-size: 1.3rem;
}

//Настройка карточек новостей
.all-news__section{
  max-width: 400px;
  padding: 1rem;
  margin: 1rem;
  border-radius: 20px;
  background: #F7F8FB;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-settings__img{
  width: 300px;
  height: 300px;
  margin: 0 auto 1rem;
  &-department{
    width: 300px;
    height: auto;
    margin: 0 auto 1rem;
  }
}

.admin-settings__h2{
  font-size: 1.3rem;
  margin: 1rem 0;
}

.admin-settings__news-p{
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.admin-settings__a{
  overflow-wrap: break-word;
  margin: 1rem 0;
  text-decoration: underline;
  color: #1934ea;
}

.settings-buttons{
  display: flex;
  flex-direction: column;
}

.updateBtn{
  margin-top: 1rem;
  border: 0;
  transition: background-color 1s;
}

.updateBtn:hover{
  background-color: #DE3435;
}

.deleteBtn{
  margin-top: 1rem;
  border: 0;
  transition: background-color 1s;
  background-color: #DE3435;
}

.deleteBtn:hover{
  background-color: #de0000;
}

.admin-settings__section{
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 1rem;
  margin: 1rem;
  background: #F7F8FB;
}

.admin-settings__p{
  margin: 1rem 0;
  overflow-wrap: break-word;
}


