.homepage{
  @media screen and (max-width: 1465px){
    padding: 0 1rem;
  }
}

//Настройка изображений
.pageCards{
  max-width: 1450px;
  margin: 2rem auto;
  @media screen and (max-width: 900px) {
    display: none;
  }
}

.pageCards__upper{
  display: flex;
  justify-content: space-between;
  gap: 5px;
  @media screen and (min-width: 900px) {
    margin-bottom: .5rem;
  }
  &-left {
    max-width: 33%;
  }
  &-center{
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 .5rem;
    gap: 10px;
  }
  &-right{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 33%;
    //@media screen and (max-width: 1321px){
    //  flex-direction: row;
    //}
  }
}

//.about-card{
//  width: 100%;
//}

.about-card{
  background-image: url("./img/bcground.webp");
  //background: linear-gradient(#000, #333);
  border-radius: 20px;
  padding: 2rem;
  color: white;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 1300px) {
    padding: 1rem;
  }
}

.about-card__title{
  font-family: Montserrat-Alternates;
  font-weight: 600;
  font-size: 4rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 1300px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 1136px) {
    font-size: 2rem;
  }
}

.about-card__text{
  font-size: 16px;
  font-weight: 400;
  font-family: Mulish-Regular;
  line-height: 27px;
  @media screen and (max-width: 1300px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1136px) {
    font-size: 11px;
    line-height: 18px;
  }
}

.media-materials, .our-projects{
  width: 100%;
  max-width: 30rem;
}

.our-events, .online-courses{
  width: 100%;
}

.pageCards__lower{
  display: flex;
  gap: 10px;
  @media screen and (max-width: 1217px) {
    justify-content: center;
    align-items: center;
  }
}

.pageCards__lower-link{
  width: 25%;
}

.pageCards__upper-link{
  width: 100%;
}

.ochnoe, .obzor, .about, .map-ncpti{
  width: 100%;
  //max-width: 21.5rem;
}

.page-cards__mobile{
  margin: 2rem 0;
  @media screen and (min-width: 901px) {
    display: none;
  }
}

.page-cards__mobile-upper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-cards__mobile-upper-img{
  max-width: 600px;
  width: 100%;
  padding: .5rem;
}

.page-cards__mobile-lower{
  display: flex;
  flex-direction: column;
  align-items: center;
  &-img{
    padding: .5rem;
    max-width: 300px;
    width: 100%;
  }
  &-first{
    display: flex;
    flex-direction: row;
  }
  &-second{
    display: flex;
    flex-direction: row;
  }
}

//Раздел Партнёров
.partners{
  max-width: 1450px;
  margin: 5rem auto;
  display: flex;
  //justify-content: center;
}

.partners-logos{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.partners-logos__title{
  margin-bottom: 2rem;
  font-family: Montserrat-Alternates;
  font-size: 2rem;
  @media screen and (max-width: 500px){
    text-align: center;
  }
}

.partners-logos img{
  //margin-right: 2rem;
  @media screen and (max-width: 500px){
    margin: 0;
  }
}

//Блок контактов
.contacts{
  display: flex;
  //background: #3D73FD;
  //flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 750px){
    flex-direction: column;
  }
  &-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 7rem;
    background: #3D73FD;
    color: white;
    width: 50%;
    font-family: Mulish-Regular;
    @media screen and (max-width: 750px){
      width: 100%;
      padding: 2rem 3rem;
    }
    &__title{
      font-size: 3rem;
      padding-bottom: 1rem;
      font-family: Montserrat-Alternates;
      font-weight: 600;
      @media screen and (max-width: 400px) {
        font-size: 2rem;
      }
    }
  }
}

.contacts-p, .contacts-a{
  padding: 1rem 0;
}

//Карта
.contacts-map{
  width: 50%;
  position:relative;
  overflow:hidden;
  @media screen and (max-width: 750px){
    width: 100%;
  }
}

.firstA{
  color:#eee;
  font-size:12px;
  position:absolute;
  top: 0px;
}

.secondA{
  color:#eee;
  font-size:12px;
  position:absolute;
  top:14px;
}

.map-iframe{
  position:relative;
  width: 100%;
  height: 450px;
}

.home-brandbook__btn{
  position: absolute;
  right: 30px;
  bottom: 30px;
  background: white;
  padding: 22px 28px 24px;
  border-radius: 100px;
  font-size: 16px;
  color: #1E2028;
  font-weight: 600;
  font-family: Mulish-Regular;
  width: 350px;
  border: 0;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.title-image__mobile-home-brandbook{
  padding: 0 1rem 1rem;
  margin-top: -2rem;
  position: relative;
  @media screen and (min-width: 716px) {
    display: none;
  }
}

.title-image__img-mobile-home-brandbook{
  width: 100%;
  border-radius: 20px;
}

.home-brandbook__btn-mobile{
  position: absolute;
  right: 30px;
  bottom: 30px;
  background: white;
  padding: 22px 28px 24px;
  border-radius: 100px;
  font-size: 16px;
  color: #1E2028;
  font-weight: 600;
  font-family: Mulish-Regular;
  width: 350px;
  border: 0;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 490px){
    padding: 20px;
    height: 30px;
    width: 150px;
  }
}