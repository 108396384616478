.account-label{
  display: flex;
  font-family: Mulish-Regular;
  flex-direction: column;
  &__radio{
    margin: .5rem auto;
    &-label{
      margin-left: .3rem;
    }
  }
}

.account-user{
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  @media screen and (max-width: 1450px) {
    margin-bottom: 2rem;
  }
  &__data{
    margin: .3rem 0;
    //padding: .5rem;
  }
  &__btns{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 400px) {
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
    }
  }
  &__btn{
    margin-top: 1rem;
    margin-right: 1rem;
  }
}

