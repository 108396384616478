.employee-about{
  position: relative;
  width: 700px;
  @media screen and (max-width: 710px) {
    width: 450px;
  }
  @media screen and (max-width: 460px) {
    width: 350px;
  }
  @media screen and (max-width: 360px) {
    width: 300px;
  }
}

.employee-modal__img{
  border-radius: 20px 20px 0 0;
  max-width: 45rem;
  width: 100%;
  height: 40rem;
  object-fit: cover;
  object-position: top;
  @media screen and (max-width: 710px) {
    height: 30rem;
  }
}

.employee-modal__description{
  padding: 2rem;
}

.employee-about__text-block{
  margin-bottom: 2rem;
}

.employee-modal__fio{
  color: #232428;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.employee-modal__title{
  color: #232428;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.employee-modal__text{
  color: #232428;
  font-size: 1rem;
  font-weight: 400;
  white-space: break-spaces;
}