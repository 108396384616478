.employee-modal{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: Modal_animation .5s forwards;
}
@keyframes Modal_animation {
  0% {opacity: 0}
  100% {opacity: 1}
}

.employee-modal__body{
  position: absolute;
  background: white;
  border-radius: 20px;
  min-width: 250px;
  max-width: 800px;
  max-height: 95vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.employee-modal.active{
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-modal__close-cross{
  position: absolute;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
  color: white;
  z-index: 99;
  font-size: 2rem;
}